.portfolio {
  padding: 2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.portfolio h2 {
  font-size: 1.5rem;
}

.portfolio-showreel {
  margin-bottom: 2rem;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: .1rem;
}

.portfolio-item {
    position: relative;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
}

.video-thumbnail img {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.video-thumbnail:hover .overlay {
  opacity: 1;
}

.video-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.video-panel-inner {
  position: relative;
  padding: 2.5rem;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  width: 60%;
  height: auto;
  display: flex;
  align-items: stretch;
}

.video-container {
  width: 64%; /* Half of the video panel */
}

.video-panel iframe {
  width: 100%;
  max-width: 100%;
}

.video-panel button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: black;
}

.info-container {
  width: 33%; /*  */
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #f9f9f9; /* Background color for the info container */
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {

    .portfolio {
	width: 100%;
	padding: 1rem 0;
	text-align: unset;
    }

    .portfolio-showreel {
	padding: 0;
	width: fit-content;
	margin: auto;
	margin-bottom: 1rem;
    }

    .portfolio-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 0rem;
	width: 95%;
	margin: auto;
    }

    .video-panel-inner {
	flex-direction: column;
	padding: .5rem;
	width: 90%;
    }

    .info-container {
	width: 100%;
	
    }

    .video-container {
	width: 100%;
	margin-top: 2rem;
    }
    
}
