/* Header.css */

.header {
    width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  z-index: 100; /* Ensure the header stays above other content */
  display: flex;
  justify-content: space-between;
  color: black;
  align-items: center;
  padding: 1rem 0;
  background-color: white;
  height: 7vh;
}

/* Style for the active link (optional) */
.header a.active {
  color: #007bff; /* Active link color */
}

.logo {
    margin-left: 1rem;
  display: flex;
  align-items: center;
}

.logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
      border-radius: 50%; /* Creates a circular shape */
  overflow: hidden; /* Hides content outside the circular shape */

}

.logo h1 {
  font-size: 1.5rem;
}

nav ul {
  list-style: none;
  display: flex;
  margin-right: 1rem;
}

nav ul li {
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

nav ul li a:hover {
  color: #6f6f6f;
}

@media only screen and (max-width: 600px) {

    .header {
	max-width: 100%;
	padding: 0;
	justify-content: space-around;
    }

    .logo h1 {
	font-size: 1rem;
    }

    nav ul {
	margin-right: 0;
	display: flex;
	padding-inline-start: 0;
    }

    nav ul li {
	margin-right: 10px;
    }
}

