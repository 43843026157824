/* Testimonials.css */

.testimonials {
  padding: 2rem;
  text-align: center;
  background-color: navy;
  color: white;
}

.testimonials h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.testimonial {
  max-width: 50%;
  padding: 1rem;
  margin: 0 auto; /* Center the testimonials within the carousel */

  text-align: center; /* Center text within testimonials */
}

.testimonial p {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.testimonial .author {
    font-weight: bold;
    font-size: 2rem
}

.testimonial .position {
    font-weight: bold;
    font-size: 1.5rem;
}

.testimonial .quote {
    margin-bottom: 2rem;
}

/* Styles for the carousel */
.carousel {
  background: none; /* Remove default carousel background */
  border: none; /* Remove default carousel border */
  box-shadow: none; /* Remove default carousel box shadow */
  width: 66%;
  margin: auto;

}


.carousel .control-arrow:hover {
    background-color: navy;
}

.carousel .slide {
  background: none; /* Remove default slide background */
}

.carousel .thumbs-wrapper {
  display: none; /* Hide the default thumbnail images */
}

@media only screen and (max-width: 600px) {
    .testimonials {
	padding: 0;
	width: 100%;
    }

    .testimonial {
	max-width: 75%;
	padding: 0;
    }

    .carousel .control-arrow {
	display: none;
    }
}
