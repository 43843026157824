/* Footer.css */


.footer {
  background-color: navy; /* Footer background color */
  color: #fff; /* Text color */
  padding: 1rem;
  text-align: center;
  height: 6vh;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust the gap between social icons */
  align-items: center;
}

.social-links a {
  text-decoration: none;
  color: #fff;
}

/* Style for social media icons (you can add custom icon images) */
.social-links img {
  width: 30px; /* Adjust the icon width */
  height: 30px; /* Adjust the icon height */
}


/* Style for the CV link */
.social-links a:last-child {
  font-weight: bold; /* Make the CV link bold */
}

@media only screen and (max-width: 600px) {

    .footer {
	padding: 1rem;
	background: gray;
	

    }

    #rights-reserved { display: none; }
    
}
