/* About.css */
.about {
    position: relative;
  height: 85vh; /* Adjust the height as needed */
  background: url('../assets/headshot 1.jpg') center/cover no-repeat; /* Replace with your actual image path */
  color: white;
  display: flex;
  align-items: center;
  justify-content: left;
  oveflow: auto;
}

.about-content {
    max-width: 50%; /* Adjust the width of the text overlay */
    max-height: 95%;
    overflow: auto;
  padding: 1rem;
  color: white;
  background: rgb(101 101 101 / 70%); /* Adjust the background color and opacity as needed */
  text-align: left;
  margin-left: 10vw;
  border-radius: 8px;
}

.about-content h2 {
  margin-bottom: 10px;
}

.about-content p {
    font-size: 1.1rem;
}

/* Additional styles for responsiveness */
@media (max-width: 768px) {
  .about-content {
      max-width: 100%;
      margin: 0 10px;
      padding-top: 25vh;
  }

  .about {
      overflow: auto;
  }
}
