/* Contact.css */

.contact {
  padding: 1rem 0;
  text-align: center;
  position: relative;
  background: navy;
}

.form-container {
  width: 50%;
  margin: 0 auto;
    background: white;
    padding: 2rem 0 ;
    color: gray;

}

.contact h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.form-group {
    margin-bottom: 1rem;
    
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 1rem
}

input[type="text"],
input[type="email"],
textarea {
  width: 50%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .contact {
	width: 100%;
	display: flex;
    }
    
    .form-container {
	width: 80%;
	padding: 0.5rem 0;
	margin: auto;
	height: 80%;
	overflow: auto;
    }
}
