.content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    height: 93vh;
    width: 100%;
    overflow: auto;
}
