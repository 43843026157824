/* Clients.css */

.clients {
  padding: 2rem;
  text-align: center;
  background: white;
}

.clients h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: black;
}

/* Assuming .client-grid is the container for your images */
.client-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 16px; /* Adjust the gap between images as needed */
  width: 66%;
  margin: 0 auto;
}

/* Style for each image */
.client-grid img {
  width: 100%; /* Take up 100% of the container's width */
  aspect-ratio: 1 / 1; /* Enforce a square aspect ratio */
  object-fit: contain; /* Crop the image to cover the container */
  /*border-radius: 8px; /* Optional: Add rounded corners */
  background: white;
  margin: 0 .5rem;
}

@media only screen and (max-width: 600px) {
    .clients {
	padding: 0;
	width: 100%;
    }

    /* Assuming .client-grid is the container for your images */
    .client-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* 3 columns */
	gap: 0; /* Adjust the gap between images as needed */
	width: 100%;
	margin: 0;
	padding: 0;
}

    /* Style for each image */
    .client-grid img {
	width: 100%; /* Take up 100% of the container's width */
	height: auto;
	aspect-ratio: 1 / 1; /* Enforce a square aspect ratio */
	object-fit: contain; /* Crop the image to cover the container */
	/*border-radius: 8px; /* Optional: Add rounded corners */
	background: white;
	margin: 0;
	padding: 0;
    }
}
